var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"dark":this.$store.state.colorVariant}},[_c('v-card-title',[_c('b',[_vm._v("Possible opportunities:")])]),_c('v-divider'),_c('v-card-text',[_c('v-data-table',{staticClass:"elevation-4",attrs:{"dark":this.$store.state.colorVariant,"disable-pagination":"","hide-default-footer":"","single-select":"","hide-default-header":"","headers":[
                         {text: 'Cost', value: 'price'},
                         {text: 'Category', value: 'category'},
                         {text: 'Description', value: 'description'} ],"fields":['cost', 'category', 'description'],"small":"","striped":"","hover":"","items":_vm.getOpportunitiesList},scopedSlots:_vm._u([{key:"item.price",fn:function(ref){
                      var item = ref.item;
return [_c('v-row',{staticStyle:{"min-width":"45px"},attrs:{"align":"center","justify":"center"}},[_vm._l((item.price),function(k){return [_c('v-img',{key:k + _vm.uuid.v4(),attrs:{"width":"20px","src":require("../../assets/img/dice/opportunity_red.png")}})]}),(item.extended)?_c('v-icon',{attrs:{"color":'#cc2338'}},[_vm._v("mdi-plus-box-outline")]):_vm._e()],2)]}},{key:"item.category",fn:function(ref){
                      var item = ref.item;
return [_vm._v(" "+_vm._s(item.category)+" ")]}},{key:"item.description",fn:function(ref){
                      var item = ref.item;
return [_c('div',{staticStyle:{"display":"inline"},domProps:{"innerHTML":_vm._s(item.description)}})]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }