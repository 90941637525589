










































































import {Player} from '@/domain/types/player.type';
import {LoadCardService} from '@/domain/services/load-card.service';
import Vue from 'vue';
export default Vue.extend({
  name: 'GmPlayersList',
  data: () => {
    return {
      xmlFiles: undefined,
      players: new Array<Player>(),
    };
  },
  beforeMount() {
    this.players = this.$store.state.gmData.players;
  },
  watch: {
    xmlFiles: async function (val) {
      if (val === undefined || val === '') {
        return;
      }

      this.players = [];

      for (const tmp of val) {
        const xml = new DOMParser().parseFromString(await tmp.text(), 'application/xml');
        this.players.push(await LoadCardService.importCard(xml, false));
      }

      this.$store.state.gmData.players = this.players;
    },
  },
  methods: {
    getAvatar: function (player: Player) {
      if (player === undefined) {
        return '';
      }

      return 'data:image/png;charset=utf-8;base64,' + player.portraitImage;
    },
    onFileChanged: function (file: File | null) {
      if (file === null) {
        return;
      }

      //@ts-ignore
      this.xmlFiles = file?.target?.files;
    },
    importXmls: function () {
      //@ts-ignore
      this.$refs.openGmPlayerXml?.click();
    },
  },
});
