































































import Vue from 'vue';
import {PersonalTraitsService, Trait} from '@/domain/services/personal-traits.service';
import {Ability, Bond, Book, PersonalTrait} from '@/domain/types/player.type';
import {BondsService, JsonBond} from '@/domain/services/bonds.service';

export default Vue.extend({
  name: 'AddBond',
  data: () => {
    return {
      dialog: false,
      selectedDistinction: '',
      nameDistinction: '',
      traits: BondsService.getBonds().map((item) => item.name),
    };
  },
  methods: {
    saveDistinction: function () {
      if (this.selectedDistinction.length <= 0) {
        return;
      }

      let jsonBond: JsonBond | undefined = BondsService.getBond(this.selectedDistinction);

      if (jsonBond === undefined) {
        return;
      }

      const bond: Bond = {
        book: jsonBond.reference.book as Book,
        page: jsonBond.reference.page.toString(),
        name: jsonBond.name,
        ability: jsonBond.ability,
        rank: 1,
      };

      if (this.nameDistinction !== null && this.nameDistinction.length > 0) {
        bond.customValue = this.nameDistinction;
      }

      this.$store.state.player.bonds.push(bond);
      this.$store.state.player.abilities.push({
        name: jsonBond.ability,
        book: jsonBond.reference.book,
        page: jsonBond.reference.page.toString(),
        source: jsonBond.name,
        description: '',
      } as Ability);
      this.dialog = false;
    },
  },
});
