























































































import OpenBookComponent from '@/components/OpenBookComponent.vue';
import Vue from 'vue';
import armorsTemplates from '../../assets/data/json/armor.json';
import {Book, NpcArmor, NpcWeapon} from '@/domain/types/player.type';

export default Vue.extend({
  name: 'AddNpcArmor',
  components: {
    OpenBookComponent,
  },
  data: (): { [key: string]: any; } => {
    return {
      dialog: false,

      weaponTypeSelected: '',
      selectedSubcategory: '',
      selectedTechnique: undefined,

      weaponTypesOptions: new Array<string>(),
      subcategoryOptions: [],

      armorList: new Array<NpcArmor>(),
    };
  },
  beforeMount() {
    for (const armorTemplate of armorsTemplates) {
      let phys = 0;
      let superNatural = 0;

      for (const resistanceValue of armorTemplate.resistance_values) {
        if (resistanceValue.category === 'Physical'){
          phys = resistanceValue.value;
        }

        if (resistanceValue.category === 'Supernatural'){
          superNatural = resistanceValue.value;
        }
      }

      this.armorList.push({
        name: armorTemplate.name,
        superRes: superNatural,
        physRes: phys,
        rarity: armorTemplate.rarity,
        reference: {
          book: armorTemplate.reference.book as Book,
          page: armorTemplate.reference.page.toString(),
        },
      });
    }
  },
  methods: {
    save: function () {
      if (this.selectedTechnique === undefined || this.selectedTechnique.length <= 0) {
        return;
      }

      this.$emit('save', {...this.selectedTechnique[0]});

      this.dialog = false;
    },
  },
});
