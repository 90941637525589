


















import Vue from 'vue';
import SideMenu from '@/components/menu/SideMenu.vue';
import TopMenu from '@/components/menu/TopMenu.vue';

export default Vue.extend({
  name: 'App',
  components: {TopMenu, SideMenu},
});
