







import Vue from 'vue';
import {Book} from '@/domain/types/player.type';

export default Vue.extend({
name: 'OpenBookComponent',
  props: {
    item: Object,
  },
  methods: {
    openBook(book: Book, page: number) {
      const adjusted = parseInt(page.toString()) + 1;
      console.log('open: ', this.$store.state.server.bookFiles[book] + '#page=' + adjusted);

      window.open(this.$store.state.server.bookFiles[book] + '#page=' + adjusted, 'modal', 'webSecurity=no,width=1000,height=800,plugins=true,nodeIntegration=no');
      },
    },
});
