




































































































































































































import Vue from 'vue';
import schools from '../assets/data/json/schools.json';
import titles from '../assets/data/json/titles.json';
import {BondsService} from '@/domain/services/bonds.service';
import {Skill} from '@/domain/types/skill.type';
import {Ring} from '@/domain/types/ring.type';
import {AdvanceService} from '@/domain/services/advance.service';
import {Book} from '@/domain/types/player.type';
import OpenBookComponent from '@/components/OpenBookComponent.vue';
import {PlayerService} from '@/domain/services/player.service';

export default Vue.extend({
  name: 'AdvancementPage',
  components: {OpenBookComponent},
  mounted() {
    const rank = PlayerService.calculateRank(this.$store.state.player);

    this.rank = rank.rank;
    this.exp = rank.totalExp;
  },
  data: (): { [key: string]: any; } => {
    return {
      dialog: false,
      schoolSelect: null,
      expPrice: 0,
      rank: 0,
      exp: 0,
      selectedDistinction: '',
      selectedAdvanceSublist: '',
      nameDistinction: '',
      traits: BondsService.getBonds().map((item) => item.name),
      advanceSublistOptions: [],
      techniquesList: [],
      advanceOptions: [
          'Skill',
          'Technique',
          'Ring',
      ],
      advanceProgressOptions: [
          'Curriculum',
          'Title',
          'Free (none)',
      ],
      isSpecialTraining: false,
      isRemoveAllRestrictions: false,
      techniquesHeaders: [
        {
          text: 'Name',
          value: 'name',
        },
        {
          text: 'Type',
          value: 'type',
        },
        {
          text: 'Subtype',
          value: 'subtype',
        },
        {
          text: 'Rank',
          value: 'rank',
        },
        {
          text: 'XP',
          value: 'xp',
        },
        {
          text: 'Book',
          value: 'book',
        },
        {
          text: 'Restriction',
          value: 'restriction',
        },
      ],

      schoolSteps: [],
      titleSteps: [],
      curriculumHeaders: [
        {
          text: 'Rank',
          value: 'rank',
        },
        {
          text: 'Advance',
          value: 'advance',
        },
        {
          text: 'Type',
          value: 'type',
        },
      ],
      titleHeaders: [
        {
          text: 'Advance',
          value: 'name',
        },
        {
          text: 'Type',
          value: 'type',
        },
      ],
      advancesHeaders: [
        {
          text: 'Type',
          value: 'type',
        },
        {
          text: 'Advance',
          value: 'name',
        },
        {
          text: 'Track',
          value: 'kind',
        },
        {
          text: 'Cost',
          value: 'cost',
        },
      ],
    };
  },
  methods: {
    getSchoolAdvances() {
      if (this.schoolSteps) {
        // return this.schoolSteps;
      }

      for (let schoolsKey in schools) {
        const school = schools[schoolsKey];

        if (school.name === this.$store.state.player.familyData.school) {
          //@ts-ignore
          this.schoolSteps = school.curriculum;
          break;
        }
      }

      return this.schoolSteps;
    },
    saveDistinction: function () {
      if (this.selectedDistinction.length <= 0) {
        return;
      }

      this.dialog = false;
    },
    selectSublist() {
      this.advanceSublistOptions = AdvanceService.getPossibleAdvances(this.selectedDistinction, this.$store.state.player);
    },
    loadTechniques() {
      if (this.selectedDistinction !== 'Technique') {
        return;
      }

      this.techniquesList = AdvanceService.getPossibleTechniques(this.selectedAdvanceSublist, this.isRemoveAllRestrictions, this.$store.state.player);
    },
    curriculumClick(item: any) {
      this.dialog = true;
      this.schoolSelect = item;
    },
    getTitleAdvances() {
      if (this.titleSteps) {
        // return this.titleSteps;
      }

      for (let titlesKey in titles) {
        const title = titles[titlesKey];

        if (title.name === this.$store.state.player.currentTitle) {
          //@ts-ignore
          this.titleSteps = title.advancements;
          break;
        }
      }

      return this.titleSteps;
    },
  },
});
