

























































































import {Npc, Player} from '@/domain/types/player.type';
import {LoadCardService} from '@/domain/services/load-card.service';
import AddNpc from '@/components/gm-panel/AddNpc.vue';
import Vue from 'vue';

export default Vue.extend({
  name: 'GmNpcList',
  components: {AddNpc},
  data: () => {
    return {
      npcs: new Array<Npc>(),
    };
  },
  beforeMount() {
    this.npcs = this.$store.state.gmData.npcs;
  },
  methods: {
    deleteNpc: function(id: string) {
      this.$store.state.gmData.npcs = this.$store.state.gmData.npcs.filter((item: Npc) => item.id !== id);
      this.npcs = this.$store.state.gmData.npcs;
    },
    refresh: function () {
      this.npcs = [];
      this.npcs = this.$store.state.gmData.npcs;
    },
    getAvatar: function (player: Npc) {
      if (player === undefined || player.portraitImage === undefined || player.portraitImage.length <= 0) {
        return require('../../assets/img/npc/placeholder.jpg');
      }

      return 'data:image/png;charset=utf-8;base64,' + player.portraitImage;
    },
    onFileChanged: async function (file: File | null) {
      if (file === null) {
        return;
      }

      //@ts-ignore
      this.$store.state.gmData.npcs = JSON.parse(await file?.target?.files[0].text());
      this.npcs = this.$store.state.gmData.npcs;
    },
    importXmls: function () {
      //@ts-ignore
      this.$refs.openGmNpcXml?.click();
    },
    exportJson: function () {
      const dataStr = 'data:text/json;charset=utf-8,' + encodeURIComponent(JSON.stringify(this.$store.state.gmData.npcs));
      const downloadAnchorNode = document.createElement('a');
      downloadAnchorNode.setAttribute('href',     dataStr);
      downloadAnchorNode.setAttribute('download', 'l5r_gm_data.rokugan.json');
      document.body.appendChild(downloadAnchorNode); // required for firefox
      downloadAnchorNode.click();
      downloadAnchorNode.remove();
    },
  },
});
