








































































































import Vue from 'vue';

export default Vue.extend({
  name: 'SideMenu',
  data: () => {
    return {
      selectedMenu: 0,
      clipped: true,
    };
  },
  computed: {
    playerAvatar() {
      if (this.$store.state.player === undefined || this.$store.state.player.portraitImage === undefined || this.$store.state.player.portraitImage.length <= 0) {
        return '';
      }

      // return 'data:' + this.detectMimeType(this.$store.state.player.image) + 'image/png;charset=utf-8;base64,' + this.$store.state.player.image;
      return 'data:image/png;charset=utf-8;base64,' + this.$store.state.player.portraitImage;
    },
  },
});
