var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-row',[_c('v-col',[_c('v-card',{attrs:{"dark":this.$store.state.colorVariant,"text-variant":this.$store.state.textVariant}},[_c('v-card-title',{staticStyle:{"justify-content":"space-between"}},[_c('b',[_vm._v("Distinctions:")]),_c('div',{staticStyle:{"display":"inline-flex"}},[_c('AddPersonalTrait',{attrs:{"type":'distinctions',"traits":_vm.distinctions}}),_c('v-btn',{staticStyle:{"margin-right":"7px"},attrs:{"color":"warning","disabled":_vm.distinctionSelect.length === 0},on:{"click":_vm.removeDistinction}},[_vm._v(" Remove ")])],1)]),_c('v-divider'),(_vm.$store.state.player !== undefined)?_c('v-card-text',[_c('v-data-table',{staticClass:"elevation-4",attrs:{"disable-pagination":"","hide-default-footer":"","single-select":"","show-select":"","headers":_vm.headers,"dark":this.$store.state.colorVariant,"items":_vm.$store.state.player.distinctions.map(function (item, index) { return Object.assign({}, item, {id: index}) })},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [(item.name.includes('[') && item.customValue !== undefined && item.customValue.length > 0)?[_vm._v(" "+_vm._s(item.name.replace(item.name.substring(item.name.indexOf('['), item.name.lastIndexOf(']') + 1), item.customValue))+" ")]:[_vm._v(" "+_vm._s(item.name)+" ")]]}},{key:"item.book",fn:function(ref){
var item = ref.item;
return [_c('OpenBookComponent',{attrs:{"item":item}})]}}],null,true),model:{value:(_vm.distinctionSelect),callback:function ($$v) {_vm.distinctionSelect=$$v},expression:"distinctionSelect"}})],1):_c('v-card-text',[_c('v-skeleton-loader',{attrs:{"type":"table"}})],1)],1)],1),_c('v-col',[_c('v-card',{attrs:{"dark":this.$store.state.colorVariant,"text-variant":this.$store.state.textVariant}},[_c('v-card-title',{staticStyle:{"justify-content":"space-between"}},[_c('b',[_vm._v("Passions:")]),_c('div',{staticStyle:{"display":"inline-flex"}},[_c('AddPersonalTrait',{attrs:{"type":'passions',"traits":_vm.passions}}),_c('v-btn',{staticStyle:{"margin-right":"7px"},attrs:{"color":"warning","disabled":_vm.passionsSelect.length === 0},on:{"click":_vm.removePassion}},[_vm._v(" Remove ")])],1)]),_c('v-divider'),(_vm.$store.state.player !== undefined)?_c('v-card-text',[_c('v-data-table',{staticClass:"elevation-4",attrs:{"disable-pagination":"","hide-default-footer":"","show-select":"","single-select":"","headers":_vm.headers,"dark":this.$store.state.colorVariant,"items":this.$store.state.player.passions.map(function (item, index) { return Object.assign({}, item, {id: index}) })},scopedSlots:_vm._u([{key:"item.book",fn:function(ref){
var item = ref.item;
return [_c('OpenBookComponent',{attrs:{"item":item}})]}}],null,true),model:{value:(_vm.passionsSelect),callback:function ($$v) {_vm.passionsSelect=$$v},expression:"passionsSelect"}})],1):_c('v-card-text',[_c('v-skeleton-loader',{attrs:{"type":"table"}})],1)],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-row',[_c('v-col',[_c('v-card',{attrs:{"dark":this.$store.state.colorVariant,"text-variant":this.$store.state.textVariant}},[_c('v-card-title',{staticStyle:{"justify-content":"space-between"}},[_c('b',[_vm._v("Adversities:")]),_c('div',{staticStyle:{"display":"inline-flex"}},[_c('AddPersonalTrait',{attrs:{"type":'adversities',"traits":_vm.adversities}}),_c('v-btn',{staticStyle:{"margin-right":"7px"},attrs:{"color":"warning","disabled":_vm.adversitiesSelect.length === 0},on:{"click":_vm.removeAdversity}},[_vm._v(" Remove ")])],1)]),_c('v-divider'),(_vm.$store.state.player !== undefined)?_c('v-card-text',[_c('v-data-table',{staticClass:"elevation-4",attrs:{"disable-pagination":"","show-select":"","hide-default-footer":"","single-select":"","headers":_vm.headers,"dark":this.$store.state.colorVariant,"items":this.$store.state.player.adversities.map(function (item, index) { return Object.assign({}, item, {id: index}) })},scopedSlots:_vm._u([{key:"item.book",fn:function(ref){
var item = ref.item;
return [_c('OpenBookComponent',{attrs:{"item":item}})]}}],null,true),model:{value:(_vm.adversitiesSelect),callback:function ($$v) {_vm.adversitiesSelect=$$v},expression:"adversitiesSelect"}})],1):_c('v-card-text',[_c('v-skeleton-loader',{attrs:{"type":"table"}})],1)],1)],1),_c('v-col',[_c('v-card',{attrs:{"dark":this.$store.state.colorVariant,"text-variant":this.$store.state.textVariant}},[_c('v-card-title',{staticStyle:{"justify-content":"space-between"}},[_c('b',[_vm._v("Anxieties:")]),_c('div',{staticStyle:{"display":"inline-flex"}},[_c('AddPersonalTrait',{attrs:{"type":'anxieties',"traits":_vm.anxieties}}),_c('v-btn',{staticStyle:{"margin-right":"7px"},attrs:{"color":"warning","disabled":_vm.anxietiesSelect.length === 0},on:{"click":_vm.removeAnxiety}},[_vm._v(" Remove ")])],1)]),_c('v-divider'),(_vm.$store.state.player !== undefined)?_c('v-card-text',[_c('v-data-table',{staticClass:"elevation-4",attrs:{"disable-pagination":"","hide-default-footer":"","show-select":"","single-select":"","headers":_vm.headers,"dark":this.$store.state.colorVariant,"items":this.$store.state.player.anxieties.map(function (item, index) { return Object.assign({}, item, {id: index}) })},scopedSlots:_vm._u([{key:"item.book",fn:function(ref){
var item = ref.item;
return [_c('OpenBookComponent',{attrs:{"item":item}})]}}],null,true),model:{value:(_vm.anxietiesSelect),callback:function ($$v) {_vm.anxietiesSelect=$$v},expression:"anxietiesSelect"}})],1):_c('v-card-text',[_c('v-skeleton-loader',{attrs:{"type":"table"}})],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }