
































































import Vue from 'vue';
import {PersonalTraitsService, Trait} from '@/domain/services/personal-traits.service';
import {Advance, Book, PersonalTrait} from '@/domain/types/player.type';

export default Vue.extend({
  name: 'AddPersonalTrait',
  data: () => {
    return {
      dialog: false,
      selectedDistinction: '',
      nameDistinction: '',
    };
  },
  props: {
    traits: Array,
    type: String,
  },
  methods: {
    saveDistinction: function () {
      if (this.selectedDistinction.length <= 0) {
        return;
      }

      let distinction: Trait | undefined;

      switch (this.type) {
        case 'anxieties':
          distinction = PersonalTraitsService.getAnxiety(this.selectedDistinction);
          break;
        case 'adversities':
          distinction = PersonalTraitsService.getAdversity(this.selectedDistinction);
          break;
        case 'passions':
          distinction = PersonalTraitsService.getPassion(this.selectedDistinction);
          break;
        case 'distinctions':
        default:
          distinction = PersonalTraitsService.getDistinction(this.selectedDistinction);
          break;
      }

      if (distinction === undefined) {
        return;
      }

      const trait: PersonalTrait = {
        book: distinction.reference.book as Book,
        page: distinction.reference.page.toString(),
        ring: distinction.ring,
        name: distinction.name,
        types: distinction.types.join(', '),
        shortDesc: '',
        desc: '',
        type: (this.type.charAt(0).toUpperCase() + this.type.slice(1).toLowerCase() )as 'Distinctions' | 'Anxieties' | 'Passions' | 'Adversities',
      };

      if (this.nameDistinction !== null && this.nameDistinction.length > 0) {
        trait.customValue = this.nameDistinction;
      }

      this.$store.state.player[this.type].push(trait);

      if (this.type === 'passions') {
        this.$store.state.player.advances.push({
          cost: 3,
          kind: 'curriculum',
          name: distinction?.name,
          type: 'Passion',
        } as Advance);
      }

      this.dialog = false;
      this.selectedDistinction = '';
      this.nameDistinction = '';
    },
  },
});
