













































































































































































































import Vue from 'vue';
import OpenBookComponent from '@/components/OpenBookComponent.vue';
import {Trait, PersonalTraitsService} from '@/domain/services/personal-traits.service';
import {PersonalTrait} from '@/domain/types/player.type';
import AddPersonalTrait from '@/components/AddPersonalTrait.vue';

export default Vue.extend({
  name: 'PersonalTraitsPage',
  components: {
    AddPersonalTrait,
    OpenBookComponent,
  },
  data: () => {
    return {
      distinctionSelect: [],
      adversitiesSelect: [],
      passionsSelect: [],
      anxietiesSelect: [],

      dialog: false,
      selectedDistinction: '',
      nameDistinction: '',
      headers: [
        {
          text: 'Name',
          value: 'name',
        },
        {
          text: 'Ring',
          value: 'ring',
        },
        {
          text: 'Type',
          value: 'type',
        },
        {
          text: 'Types',
          value: 'types',
        },
        {
          text: 'Book',
          value: 'book',
        },
      ],
      distinctions: PersonalTraitsService.getDistinctions().map((item) => item.name),
      adversities: PersonalTraitsService.getAdversities().map((item) => item.name),
      anxieties: PersonalTraitsService.getAnxieties().map((item) => item.name),
      passions: PersonalTraitsService.getPassions().map((item) => item.name),
    };
  },
  methods: {
    removeAdversity: function () {
      if (this.adversitiesSelect.length <= 0) {
        return;
      }

      const trait: PersonalTrait = this.adversitiesSelect[0];

      for (let i = 0; i < this.$store.state.player.adversities.length; i++) {
        const tmp = this.$store.state.player.adversities[i];
        if (trait.name === tmp.name) {
          this.$store.state.player.adversities.splice(i, 1);
          break;
        }
      }

      this.adversitiesSelect = [];
    },
    removeAnxiety: function () {
      if (this.anxietiesSelect.length <= 0) {
        return;
      }

      const trait: PersonalTrait = this.anxietiesSelect[0];

      for (let i = 0; i < this.$store.state.player.anxieties.length; i++) {
        const tmp = this.$store.state.player.anxieties[i];
        if (trait.name === tmp.name) {
          this.$store.state.player.anxieties.splice(i, 1);
          break;
        }
      }

      this.anxietiesSelect = [];
    },
    removePassion: function () {
      if (this.passionsSelect.length <= 0) {
        return;
      }

      const trait: PersonalTrait = this.passionsSelect[0];

      for (let i = 0; i < this.$store.state.player.passions.length; i++) {
        const tmp = this.$store.state.player.passions[i];
        if (trait.name === tmp.name) {
          this.$store.state.player.passions.splice(i, 1);
          break;
        }
      }

      this.passionsSelect = [];
    },
    removeDistinction: function () {
      if (this.distinctionSelect.length <= 0) {
        return;
      }

      const trait: PersonalTrait = this.distinctionSelect[0];

      for (let i = 0; i < this.$store.state.player.distinctions.length; i++) {
        const tmp = this.$store.state.player.distinctions[i];

        if (trait.name === tmp.name) {
          this.$store.state.player.distinctions.splice(i, 1);
          break;
        }
      }

      this.distinctionSelect = [];
    },
  },

});
