var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',[_c('v-col',[_c('v-card',{attrs:{"dark":this.$store.state.colorVariant,"text-variant":this.$store.state.textVariant}},[_c('v-card-title',{staticStyle:{"justify-content":"space-between"}},[_c('b',[_vm._v("Weapons:")])]),_c('v-divider'),(_vm.$store.state.player !== undefined)?_c('v-card-text',[_c('v-data-table',{staticClass:"elevation-4",attrs:{"disable-pagination":"","hide-default-footer":"","single-select":"","headers":_vm.weaponsHeaders,"dark":this.$store.state.colorVariant,"items":this.$store.state.player.equipment.filter(function (item) { return item.kind === 'weapon'; })},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" ("+_vm._s(item.grip)+") ")]}},{key:"item.damage",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.getDamageColorScale(item.damage),"dark":_vm.$store.state.colorVariant}},[_vm._v(" "+_vm._s(item.damage)+" ")])]}},{key:"item.deadlines",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.getDamageColorScale(item.deadlines),"dark":_vm.$store.state.colorVariant}},[_vm._v(" "+_vm._s(item.deadlines)+" ")])]}},{key:"item.qualities",fn:function(ref){
var item = ref.item;
return _vm._l((item.qualities.split(' ').filter(function (val) { return val.length > 0; })),function(quality){return _c('v-chip',{key:quality + _vm.uuid.v4(),attrs:{"color":_vm.getQualityColor(quality),"dark":_vm.$store.state.colorVariant},on:{"click":function($event){return _vm.openQualityBook(quality)}}},[_vm._v(" "+_vm._s(quality)+" ")])})}},{key:"item.price",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.price)+" "+_vm._s(item.unit)+" ")]}},{key:"item.range",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.minRange)+" - "+_vm._s(item.maxRange)+" ")]}},{key:"item.book",fn:function(ref){
var item = ref.item;
return [_c('OpenBookComponent',{attrs:{"item":item}})]}}],null,true)})],1):_c('v-card-text',[_c('v-skeleton-loader',{attrs:{"type":"table"}})],1)],1)],1)],1)],1),_c('v-col',{attrs:{"sm":"12","md":"7"}},[_c('v-row',[_c('v-col',[_c('v-card',{attrs:{"dark":this.$store.state.colorVariant,"text-variant":this.$store.state.textVariant}},[_c('v-card-title',{staticStyle:{"justify-content":"space-between"}},[_c('b',[_vm._v("Armors:")])]),_c('v-divider'),(_vm.$store.state.player !== undefined)?_c('v-card-text',[_c('v-data-table',{staticClass:"elevation-4",attrs:{"disable-pagination":"","hide-default-footer":"","single-select":"","headers":_vm.armorsHeaders,"dark":this.$store.state.colorVariant,"items":this.$store.state.player.equipment.filter(function (item) { return item.kind === 'armor'; })},scopedSlots:_vm._u([{key:"item.physRes",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.getArmorColorScale(item.physRes),"dark":_vm.$store.state.colorVariant}},[_vm._v(" "+_vm._s(item.physRes)+" ")])]}},{key:"item.superRes",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.getArmorColorScale(item.superRes),"dark":_vm.$store.state.colorVariant}},[_vm._v(" "+_vm._s(item.superRes)+" ")])]}},{key:"item.qualities",fn:function(ref){
var item = ref.item;
return _vm._l((item.qualities.split(' ').filter(function (val) { return val.length > 0; })),function(quality){return _c('v-chip',{key:quality + _vm.uuid.v4(),attrs:{"color":_vm.getQualityColor(quality),"dark":_vm.$store.state.colorVariant},on:{"click":function($event){return _vm.openQualityBook(quality)}}},[_vm._v(" "+_vm._s(quality)+" ")])})}},{key:"item.price",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.price)+" "+_vm._s(item.unit)+" ")]}},{key:"item.book",fn:function(ref){
var item = ref.item;
return [_c('OpenBookComponent',{attrs:{"item":item}})]}}],null,true)})],1):_c('v-card-text',[_c('v-skeleton-loader',{attrs:{"type":"table"}})],1)],1)],1)],1)],1),_c('v-col',{attrs:{"sm":"12","md":"5"}},[_c('v-row',[_c('v-col',[_c('v-card',{attrs:{"dark":this.$store.state.colorVariant,"text-variant":this.$store.state.textVariant}},[_c('v-card-title',{staticStyle:{"justify-content":"space-between"}},[_c('b',[_vm._v("Other:")])]),_c('v-divider'),(_vm.$store.state.player !== undefined)?_c('v-card-text',[_c('v-data-table',{staticClass:"elevation-4",attrs:{"disable-pagination":"","hide-default-footer":"","single-select":"","headers":_vm.othersHeaders,"dark":this.$store.state.colorVariant,"items":this.$store.state.player.equipment.filter(function (item) { return item.kind === 'other'; })},scopedSlots:_vm._u([{key:"item.qualities",fn:function(ref){
var item = ref.item;
return _vm._l((item.qualities.split(' ').filter(function (val) { return val.length > 0; })),function(quality){return _c('v-chip',{key:quality + _vm.uuid.v4(),attrs:{"color":_vm.getQualityColor(quality),"dark":_vm.$store.state.colorVariant},on:{"click":function($event){return _vm.openQualityBook(quality)}}},[_vm._v(" "+_vm._s(quality)+" ")])})}},{key:"item.price",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.price)+" "+_vm._s(item.unit)+" ")]}},{key:"item.book",fn:function(ref){
var item = ref.item;
return [_c('OpenBookComponent',{attrs:{"item":item}})]}}],null,true)})],1):_c('v-card-text',[_c('v-skeleton-loader',{attrs:{"type":"table"}})],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }