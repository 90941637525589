
































































































import Vue from 'vue';

export default Vue.extend({
  name: 'AddNpcAdvantage',
  data() {
    return {
      addDialog: false,
      valid: true,
      types: [
        'Interpersonal',
        'Interpersonal (Name)',
        'Social',
        'Mental',
        'Mental and Interpersonal (Culture)',
        'Spiritual',
        'Physical',
        'Physical (Appearance)',
        'Virtue',
        'Curse',
        'Scar',
        'Physical or Mental',
        'Flaw',
        'Fame',
        'Martial',
        'Infamy',
      ],

      advantageName: undefined,
      advantageSkill: undefined,
      advantageRing: undefined,
      advantageTypes: undefined,
    };
  },
  methods: {
    save: function () {
      //@ts-ignore
      if (this.$refs.form.validate() === false) {
        return;
      }

      this.$emit('save', {
        advantageName: this.advantageName,
        advantageSkill: this.advantageSkill,
        advantageRing: this.advantageRing,
        advantageTypes: this.advantageTypes,
      });
      this.addDialog = false;
      this.advantageName = undefined;
      this.advantageSkill = undefined;
      this.advantageRing = undefined;
      this.advantageTypes = undefined;
    },
  },
});
