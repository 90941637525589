






































import {Skill} from '@/domain/types/skill.type';
import Vue from 'vue';

export default Vue.extend({
  name: 'SelectSkillComponent',
  data() {
    return {
      uuid: require('uuid'),
    };
  },
  methods: {
    skillSelect: function (val: Skill) {
      if (this.$store.state.mainRoll.isDuringRoll) {
        return;
      }

      this.$store.state.mainRoll.selectedSkill = val.name;
      this.$store.state.mainRoll.selectedSkillValue = val.value;

      this.$store.state.mainRoll.wDices = [];
      this.$store.state.mainRoll.selectedIds = [];

      for (let i = 0; i < this.$store.state.mainRoll.selectedSkillValue; i++) {
        this.$store.state.mainRoll.wDices.push({
          id: this.uuid.v4(),
          img: 'white.png',
        });
      }

      localStorage.setItem('mainRoll', JSON.stringify(this.$store.state.mainRoll));
    },
  },
});
