




















































































import OpenBookComponent from '@/components/OpenBookComponent.vue';
import Vue from 'vue';
import weaponsTemplates from '../../assets/data/json/weapons.json';
import {Book, NpcWeapon} from '@/domain/types/player.type';

export default Vue.extend({
  name: 'AddNpcWeapon',
  components: {
    OpenBookComponent,
  },
  data: (): { [key: string]: any; } => {
    return {
      dialog: false,

      weaponTypeSelected: '',
      selectedSubcategory: '',
      selectedTechnique: undefined,

      weaponTypesOptions: new Array<string>(),
      subcategoryOptions: [],

      weaponList: new Array<NpcWeapon>(),

      techniquesHeaders: [
        {
          text: 'Name',
          value: 'name',
        },
        {
          text: 'Type',
          value: 'type',
        },
        {
          text: 'Subtype',
          value: 'subtype',
        },
        {
          text: 'Rank',
          value: 'rank',
        },
        {
          text: 'Book',
          value: 'book',
        },
      ],
    };
  },
  beforeMount() {
    for (const group of weaponsTemplates) {
      this.weaponTypesOptions.push(group.name as string);
    }
  },
  methods: {
    save: function () {
      if (this.selectedTechnique === undefined || this.selectedTechnique.length <= 0) {
        return;
      }

      this.$emit('save', {...this.selectedTechnique[0]});

      this.dialog = false;
    },
    loadWeapons() {
      for (const group of weaponsTemplates) {
        if (group.name !== this.weaponTypeSelected) {
          continue;
        }

        this.weaponList = [];

        for (const weapon of group.entries) {
          let damage = '';
          let deadlines = '';

          for (const grip of weapon.grips) {
            for (const effect of grip.effects) {
              if (effect.attribute === 'damage' && effect?.value_increase) {
                damage = '/' + (weapon.damage + effect?.value_increase).toString();
              }

              if (effect.attribute === 'deadliness' && effect?.value_increase) {
                deadlines = '/' + (weapon.deadliness + effect.value_increase).toString();
              }
            }
          }

          this.weaponList.push({
            name: weapon.name,
            damage: weapon.damage.toString() + damage,
            deadliness: weapon.deadliness.toString() + deadlines,
            range: {
              min: weapon.range.min,
              max: weapon.range.max,
            },
            reference: {
              book: weapon.reference.book as Book,
              page: weapon.reference.page.toString(),
            },
          } as NpcWeapon);
        }
        break;
      }
    },
  },
});
