



















































































import schools from '@/assets/data/json/schools.json';
import {AdvanceService} from '@/domain/services/advance.service';
import titles from '@/assets/data/json/titles.json';
import techniques from '../../assets/data/json/techniques.json';
import OpenBookComponent from '@/components/OpenBookComponent.vue';
import Vue from 'vue';

export default Vue.extend({
  name: 'AddNpcAbility',
  components: {
    OpenBookComponent,
  },
  data: (): { [key: string]: any; } => {
    return {
      dialog: false,

      selectedAdvanceSublist: '',
      selectedSubcategory: '',
      selectedTechnique: undefined,

      advanceSublistOptions: new Array<string>(),
      subcategoryOptions: [],

      techniquesList: [],

      techniquesHeaders: [
        {
          text: 'Name',
          value: 'name',
        },
        {
          text: 'Type',
          value: 'type',
        },
        {
          text: 'Subtype',
          value: 'subtype',
        },
        {
          text: 'Rank',
          value: 'rank',
        },
        {
          text: 'Book',
          value: 'book',
        },
      ],
    };
  },
  beforeMount() {
    for (const group of techniques) {
      this.advanceSublistOptions.push(group.name as string);
    }
  },
  methods: {
    saveDistinction: function () {
      if (this.selectedTechnique === undefined || this.selectedTechnique.length <= 0) {
        return;
      }

      this.$emit('save', {...this.selectedTechnique[0]});

      this.dialog = false;
    },
    loadSubcategories() {
      for (const group of techniques) {
        if (group.name !== this.selectedAdvanceSublist) {
          continue;
        }

        this.techniquesList = [];
        this.subcategoryOptions = [];
        for (const subcategory of group.subcategories) {
          this.subcategoryOptions.push(subcategory.name);
        }
        break;
      }
    },
    loadTechniques() {
      for (const group of techniques) {
        if (group.name !== this.selectedAdvanceSublist) {
          continue;
        }

        for (const subcategory of group.subcategories) {
          if (subcategory.name !== this.selectedSubcategory) {
            continue;
          }

          this.techniquesList = [];

          for (const technique of subcategory.techniques) {
            this.techniquesList.push({...technique, type: group.name, subtype: subcategory.name});
          }

          break;
        }
        break;
      }
    },
  },
});
