var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"container-color",attrs:{"dark":this.$store.state.colorVariant,"text-variant":this.$store.state.textVariant}},[_c('v-card-title',[_c('b',[_vm._v("Select ring:")])]),_c('v-divider'),(_vm.$store.state.player !== undefined)?_c('v-card-text',[_c('v-data-table',{staticClass:"elevation-4",attrs:{"disable-pagination":"","hide-default-footer":"","single-select":"","hide-default-header":"","headers":[
                         {text: 'Name', value: 'name', width: 50},
                         {text: 'Description', value: 'description'},
                         {text: 'Value', value: 'value'} ],"dark":this.$store.state.colorVariant,"items":this.$store.state.player.rings},on:{"click:row":_vm.ringSelect},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
                      var item = ref.item;
return [_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
                      var attrs = ref.attrs;
return [_c('v-img',_vm._g(_vm._b({attrs:{"id":item.name.toLowerCase() + '-ring',"width":"55px","src":require('../../assets/img/ring/' + item.name.toLowerCase() + '.png')}},'v-img',attrs,false),on))]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.getLocalStanceTooltip(item.name.toLowerCase())))])])]}},{key:"item.description",fn:function(ref){
                      var item = ref.item;
return [_c('b',[_vm._v(_vm._s(_vm.getDescriptionRing(item.name)))])]}},{key:"item.value",fn:function(ref){
                      var item = ref.item;
return [_c('b',[_vm._v(_vm._s(item.value))])]}}],null,true)})],1):_c('v-card-text',[_c('v-skeleton-loader',{attrs:{"type":"table"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }