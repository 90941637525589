






















































import {Ring} from '@/domain/types/ring.type';
import {getRingDescription, getStanceTooltip} from '@/domain/common';
import Vue from 'vue';

export default Vue.extend({
  name: 'SelectRingComponent',
  data() {
    return {
      uuid: require('uuid'),
    };
  },
  methods: {
    ringSelect: function (val: Ring) {
      if (this.$store.state.mainRoll.isDuringRoll) {
        return;
      }

      this.$store.state.mainRoll.selectedRing = val.name;
      this.$store.state.mainRoll.selectedRingValue = val.value;

      this.$store.state.mainRoll.bDices = [];
      this.$store.state.mainRoll.selectedIds = [];

      for (let i = 0; i < this.$store.state.mainRoll.selectedRingValue; i++) {
        this.$store.state.mainRoll.bDices.push({
          id: this.uuid.v4(),
          img: 'black.png',
        });
      }

      localStorage.setItem('mainRoll', JSON.stringify(this.$store.state.mainRoll));
    },
    getLocalStanceTooltip: function (stance: string) {
      return getStanceTooltip(stance);
    },
    getDescriptionRing: function (ring: string) {
      return getRingDescription(ring);
    },
  },
});
