
















import Vue from 'vue';
import GmPlayersList from '@/components/gm-panel/GmPlayersList.vue';
import GmNpcList from '@/components/gm-panel/GmNpcList.vue';

export default Vue.extend({
  name: 'GmPanel',
  components: {GmPlayersList, GmNpcList},
  data: () => {
    return {
      npcHeaders: [
        {
          text: 'Name',
          value: 'name',
        },
      ],
      npcList: [
        {
          name: 'Janusz',
        },
      ],
    };
  },
  methods: {

  },
});
