








































































































































































































































































































































































































































































































































































































































import Vue from 'vue';
import OpenBookComponent from '@/components/OpenBookComponent.vue';
import {Bond, CurrentStatistics, Npc, Player} from '@/domain/types/player.type';
import {getHook, getKami, getRingDescription, getStanceTooltip} from '@/domain/common';
import demeanorTemplates from '@/assets/data/json/demeanor.json';
import MakeRollComponent from '@/components/roll-page/MakeRollComponent.vue';
import AddNpc from '@/components/gm-panel/AddNpc.vue';

export default Vue.extend({
  name: 'NpcDetailsPage',
  components: {
    OpenBookComponent,
    MakeRollComponent,
  },
  props: {
    id: String,
  },
  beforeMount() {
    this.player = this.$store.state.gmData.npcs[this.id];
  },
  watch: {
    comp (newCount) {
      if (newCount != 0) {
        return;
      }

      const hook = atob(getHook());

      fetch(hook + '/slack', {
        method: 'POST',
        mode: 'no-cors',
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          attachments: [
            {
              fields: [],
              title: this.player.name,
              text: 'Bends under pressure! (Unmasking)',
            },
          ],
          ...getKami(this.$store?.state?.kami, true),
        }),
      });
    },
  },
  data: () => {
    return {
      eqStepper: 1,
      skillStepper: 1,
      player: {} as Npc,
      bondSelect: [],
      bondsHeaders: [
        {
          text: 'Name',
          value: 'name',
        },
        {
          text: 'Rank',
          value: 'rank',
        },
        {
          text: 'Book',
          value: 'book',
        },
      ],
    };
  },
  computed: {
    comp () {
      return this.player?.currentStats?.composure;
    },
    endu () {
      return this.player?.currentStats?.endurance;
    },
    playerAvatar() {
      if (this.player === undefined || this.player.portraitImage === undefined || this.player.portraitImage.length <= 0) {
        return require('../../assets/img/npc/placeholder.jpg');
      }

      return 'data:image/png;charset=utf-8;base64,' + this.player.portraitImage;
    },
    demeanors: function () {
      return demeanorTemplates.map(item => item.name);
    },
  },
  methods: {
    refresh: function() {
      this.player = {...this.$store.state.gmData.npcs[this.id]};
    },
    updateStats: function (val: CurrentStatistics) {
      (this.$store.state.gmData.npcs[this.id] as Npc).currentStats = val;
      this.player = this.$store.state.gmData.npcs[this.id];
    },
    selectDemeanor: function (newValue: string) {
      for (const demeanorTemplate of demeanorTemplates) {
        if (demeanorTemplate.name === newValue) {
          this.player.demeanor = demeanorTemplate;
          break;
        }
      }
    },
    getLocalStanceTooltip: function (stance: string) {
      return getStanceTooltip(stance);
    },
    getDescriptionRing: function (ring: string) {
      return getRingDescription(ring);
    },
    getBonds() {
      if (this.player === undefined) {
        return [];
      }

      // @ts-ignore
      return this.player?.bonds?.map((item: Bond, index: number) => {
        return {
          ...item,
          id: index,
        };
      });
    },
  },
});
