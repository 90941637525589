











































import opportunities, {OpportunityParams} from '@/domain/consts/opportunities';
import Vue from 'vue';
import {RingEnum} from '@/domain/types/ring.type';

export default Vue.extend({
  name: 'OportunitiesComponent',
  data() {
    return {
      uuid: require('uuid'),
    };
  },
  computed: {
    getOpportunitiesList: function () {
      if (this.$store.state.mainRoll.opportunities == 0) {
        return [];
      }

      const selectedRing: RingEnum = this.$store.state.mainRoll.selectedRing.toLowerCase();

      let result = [];
      let general: OpportunityParams[] = opportunities.general[selectedRing] || [];
      let downtime: OpportunityParams[] = opportunities.downtime[selectedRing] || [];
      let common: OpportunityParams[] = opportunities.general['common'] || [];
      general = general.filter(val => val.price <= this.$store.state.mainRoll.opportunities).map((val) => {
        return {
          ...val,
          category: 'General',
        };
      });
      downtime = downtime.filter(val => val.price <= this.$store.state.mainRoll.opportunities).map((val) => {
        return {
          ...val,
          category: 'Free time',
        };
      });
      common = common.filter(val => val.price <= this.$store.state.mainRoll.opportunities).map((val) => {
        return {
          ...val,
          category: 'General (Common)',
        };
      });


      if (['Sentiment', 'Meditation', 'Tactics', 'Command'].includes(this.$store.state.mainRoll.selectedSkill)) {
        let initiative: OpportunityParams[] = opportunities.initiative[selectedRing] || [];
        initiative = initiative.filter(val => val.price <= this.$store.state.mainRoll.opportunities).map((val) => {
          return {
            ...val,
            category: 'Initiative',
          };
        });

        result.push(...initiative);
      }

      if (this.$store.state.mainRoll.selectedSkill.includes('Martial')) {
        let martial: OpportunityParams[] = opportunities.martial[selectedRing] || [];
        martial = martial.filter(val => val.price <= this.$store.state.mainRoll.opportunities).map((val) => {
          return {
            ...val,
            category: 'Conflict',
          };
        });

        result.push(...martial);
      }

      result.push(...general);
      result.push(...common);
      result.push(...downtime);

      return result;
    },
  },
});
