

















































































































































































































































































import Vue from 'vue';
import OpenBookComponent from '@/components/OpenBookComponent.vue';
import {Bond, Player} from '@/domain/types/player.type';
import {getRingDescription, getStanceTooltip} from '@/domain/common';

export default Vue.extend({
  name: 'PlayerDetailsPage',
  components: {
    OpenBookComponent,
  },
  props: {
    id: String,
  },
  beforeMount() {
    this.player = this.$store.state.gmData.players[this.id];
  },
  data: () => {
    return {
      player: {

      },
      bondSelect: [],
      bondsHeaders: [
        {
          text: 'Name',
          value: 'name',
        },
        {
          text: 'Rank',
          value: 'rank',
        },
        {
          text: 'Book',
          value: 'book',
        },
      ],
    };
  },
  methods: {

    getLocalStanceTooltip: function (stance: string) {
      return getStanceTooltip(stance);
    },
    getDescriptionRing: function (ring: string) {
      return getRingDescription(ring);
    },
    getBonds() {
      if (this.player === undefined) {
        return [];
      }

      // @ts-ignore
      return this.player?.bonds?.map((item: Bond, index: number) => {
        return {
          ...item,
          id: index,
        };
      });
    },
  },
});
