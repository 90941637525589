









































































import Vue from 'vue';
import {Book} from '@/domain/types/player.type';
import OpenBookComponent from '@/components/OpenBookComponent.vue';

export default Vue.extend({
  name: 'TechniquesPage',
  components: {
    OpenBookComponent,
  },
  data: () => {
    return {
      techniquesHeaders: [
        {
          text: 'Name',
          value: 'name',
        },
        {
          text: 'Type',
          value: 'type',
        },
        {
          text: 'Subtype',
          value: 'subtype',
        },
        {
          text: 'Rank',
          value: 'rank',
        },
        {
          text: 'Restriction',
          value: 'restriction',
        },

        {
          text: 'Book',
          value: 'book',
        },
      ],
      abilitiesHeaders: [
        {
          text: 'Name',
          value: 'name',
        },
        {
          text: 'Source',
          value: 'source',
        },
        {
          text: 'Book',
          value: 'book',
        },
      ],
    };
  },
});
