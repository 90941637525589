var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"persistent":"","dark":this.$store.state.colorVariant,"max-width":"1100px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){return [_c('v-btn',{staticStyle:{"margin-right":"7px"},attrs:{"color":"success"},on:{"click":function($event){_vm.dialog = true}}},[_vm._v(" Add ")])]}}]),model:{value:(this.dialog),callback:function ($$v) {_vm.$set(this, "dialog", $$v)},expression:"this.dialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"text-h5"},[_vm._v("Add Weapon")])]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"elevation-4",attrs:{"disable-pagination":"","hide-default-footer":"","single-select":"","dense":"","show-select":"","item-key":"name","headers":[
                          {
                            text: 'Name',
                            value: 'name',
                          },
                          {
                            text: 'Physical Resistance',
                            value: 'physRes',
                          },
                          {
                            text: 'Supernatural Resistance',
                            value: 'superRes',
                          },
                          {
                            text: 'Rarity',
                            value: 'rarity',
                          },
                          {
                            text: 'Book',
                            value: 'reference',
                          } ],"dark":this.$store.state.colorVariant,"items":this.armorList},scopedSlots:_vm._u([{key:"item.reference",fn:function(ref){
                        var item = ref.item;
return [(item.reference)?_c('OpenBookComponent',{attrs:{"item":item.reference}}):_vm._e()]}}],null,true),model:{value:(_vm.selectedTechnique),callback:function ($$v) {_vm.selectedTechnique=$$v},expression:"selectedTechnique"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" Close ")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.save}},[_vm._v(" Save ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }