








































































































































































































































import Vue from 'vue';
import OpenBookComponent from '@/components/OpenBookComponent.vue';
import AddBond from '@/components/AddBond.vue';
import {Advance, Bond} from '@/domain/types/player.type';
import * as uuid from 'uuid';

export default Vue.extend({
  name: 'BackgroundPage',
  components: {
    AddBond,
    OpenBookComponent,
  },
  data: () => {
    return {
      bondSelect: [],
      bondsHeaders: [
        {
          text: 'Name',
          value: 'name',
        },
        {
          text: 'Rank',
          value: 'rank',
        },
        {
          text: 'Book',
          value: 'book',
        },
      ],
    };
  },
  methods: {
    upgradeBond() {
      if (this.bondSelect === undefined || this.bondSelect.length <= 0) {
        return;
      }

      const selected: Bond = this.bondSelect[0];

      if (selected.rank >= 5) {
        return;
      }

      for (let i = 0; i < this.$store.state.player.bonds.length; i++) {
        const bond = this.$store.state.player.bonds[i];

        if (bond.name === selected.name && bond.rank === selected.rank) {
          bond.rank++;

          this.$store.state.player.advances.push({
            type: 'Bond Upgrade',
            name: bond.name,
            kind: 'none',
            cost: bond.rank * 2,
          } as Advance);

          break;
        }
      }

      this.bondSelect = [];
    },
    removeBond() {
      if (this.bondSelect === undefined || this.bondSelect.length <= 0) {
        return;
      }

      const selected: Bond = this.bondSelect[0];

      for (let i = 0; i < this.$store.state.player.bonds.length; i++) {
        const bond = this.$store.state.player.bonds[i];

        if (bond.name === selected.name && bond.rank === selected.rank) {
          this.$store.state.player.bonds.splice(i, 1);

          for (let j = 0; j < this.$store.state.player.abilities.length; j++) {
            const ability = this.$store.state.player.abilities[j];

            if (ability.source === bond.name) {
              this.$store.state.player.abilities.splice(j, 1);
              break;
            }
          }

          break;
        }
      }

      this.bondSelect = [];
    },
    getBonds() {
      if (this.$store.state.player === undefined) {
        return [];
      }

      return this.$store.state.player.bonds.map((item: Bond, index: number) => {
        return {
          ...item,
          id: index,
        };
      });
    },
    changeAvatar() {
      //todo
    },
  },
});
